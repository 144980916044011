import React from "react"
import { getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CircleBg from "../../../../static/svg/Large-Circle.svg"
import { GradientFooterBg } from "../../Footer"
import { Heading24 } from "../../../styles/PageStyles"
import { RiExternalLinkLine } from "react-icons/ri"

const GlobalContentId = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "global-id" } }) {
        frontmatter {
          externalLinks {
            title
            link
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            animationDelay
            hidden
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark

  return (
    <GlobalContainer fluid>
      <Row className="pt-100 pb-100">
        {frontmatter.externalLinks.map((external, index) => (
          <>
            {!external.hidden && (
              <GlobalCard
                key={index}
                lg={4}
                data-aos="fade-down"
                data-aos-delay={external.animationDelay}
              >
                <a
                  className="content-wrapper"
                  href={external.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="global-title">
                    <Heading24>
                      {external.title}&nbsp;
                      <RiExternalLinkLine />
                    </Heading24>
                  </div>
                  <div className="global-image">
                    <GatsbyImage
                      image={getImage(external.image)}
                      alt={external.title}
                    />
                  </div>
                </a>
              </GlobalCard>
            )}
          </>
        ))}
      </Row>
      <CircleBg className="circle-bg" />
      <GradientFooterBg />
    </GlobalContainer>
  )
}

export default GlobalContentId

const GlobalContainer = styled(Container)`
  position: relative;
  background: #f7f8fc;
  overflow: hidden;
  z-index: 1;
  padding: 0;

  .circle-bg {
    position: absolute;
    top: 20%;
    left: -40%;
    z-index: -1;
  }

  .row {
    margin: 0 10%;
    display: flex;
    margin-top: -50px;
  }

  @media only screen and (max-width: 1025px) {
    height: inherit;

    .row {
      justify-content: center;
    }
  }
`

const GlobalCard = styled(Col)`
  padding: 0;
  margin-bottom: 50px;

  .content-wrapper {
    background: #fff;
    box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    height: 100%;
    margin: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s ease-in-out;

    .global-title {
      position: absolute;
      top: -32.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;
      min-height: 65px;
      background: linear-gradient(
        180deg,
        #d1e869 0%,
        #c6de5a 27.4%,
        #aac532 100%
      );
      border-radius: 10px;

      h1 {
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.06em;
        line-height: 25px;
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
        margin-bottom: 5px;
      }
    }

    .global-image {
      padding: 25px;
      margin-top: 32.5px;

      img {
        max-width: 100%;
      }
    }

    &:hover {
      transform: scale(1.05);
      transition: 0.3s ease-in-out;
    }
  }

  @media only screen and (max-width: 1025px) {
    width: 80%;
    margin-bottom: 0;
    padding-bottom: 100px;

    .content-wrapper {
      margin: 0 !important;
    }
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    padding-bottom: 75px;
  }
`
